:root {
  --dark-green: #3c7521;
  --light-magento: #ec624b;
  --dark-magento: #983230;
  --dark-blue: #0b2739;
  --pitaya: #ff0066;
  --light-orange: #fdf2df;
  --light-yellow: #fcf6f7;
  --light-green: #d6ffd6;
  --light-blue: #c2f6fa;
  --light-purple: #ffd9ff;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.fade-in {
  animation: fadeIn 1s;
}

/*Backgrounds Colors*/

.bg-light-green {
  background-color: var(--light-green);
}
.bg-light-orange {
  background-color: var(--light-orange);
}
.bg-light-yellow {
  background-color: var(--light-yellow);
}
.bg-light-blue {
  background-color: var(--light-blue);
}
.bg-light-purple {
  background-color: var(--light-purple);
}

.bg-dark-blue {
  background-color: var(--dark-blue);
}
.bg-pitaya {
  background-color: var(--pitaya) !important;
}

/*Radius*/
.rounded-start-4 {
  border-bottom-left-radius: var(--bs-border-radius-xl);
  border-top-left-radius: var(--bs-border-radius-xl);
}
.rounded-end-4 {
  border-bottom-right-radius: var(--bs-border-radius-xl);
  border-top-right-radius: var(--bs-border-radius-xl);
}

.w-275-px {
  width: 275px;
}
.btn-logout {
  cursor: pointer;
  position: fixed !important;
  top:1em !important;
  right: 1em !important;
}
